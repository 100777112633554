<template>
  <section id="prospect">
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Customer Information">
          <b-row>
            <b-col md="6">
              <div class="detail-info">
                <span>Customer Name</span>
                <h4>{{customerInformation.tp_client_name || '-'}}</h4>
              </div>
            </b-col>
            <b-col md="6">
              <div class="detail-info">
                <span>Contact</span>
                <h4 v-if="customerInformation.tp_client_email"><a :href="'mailto:' + customerInformation.tp_client_email" class="contact-info"><i class="ti-email"></i><span>{{ customerInformation.tp_client_email || '-'}}</span></a></h4>
                <h4 v-if="customerInformation.tp_client_phone"><a :href="'https://web.whatsapp.com/send?phone='+customerInformation.tp_client_phone.replace('08', '628')" target="child" class="contact-info"><i class="fab fa-whatsapp"></i><span>{{ customerInformation.tp_client_phone || '-'}}</span></a></h4>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <div class="detail-info">
                <span>Prospect Date</span>
                <h4>{{ customerInformation.tp_date | moment("LLLL") }}</h4>
              </div>
            </b-col>

            <b-col md="6" v-if="customerInformation.tp_status_prospect == 'C' && customerInformation.tp_closing_date">
              <div class="detail-info">
                <span>Closing Date</span>
                <h4>{{ customerInformation.tp_closing_date | moment("LL") }}</h4>
              </div>
            </b-col>
            <b-col md="6">
              <div class="detail-info">
                <span>Address</span>
                <h4>{{ customerInformation.tp_client_address || '-' }}</h4>
              </div>
            </b-col>
            <b-col md="6">
              <div class="detail-info">
                <span>Category</span>
                <h4>{{ customerInformation.mpct_name || '-' }}</h4>
              </div>
            </b-col>
            <b-col md="6">
              <div class="detail-info">
                <span>Purpose</span>
                <h4>{{ customerInformation.tp_keperluan || '-' }}</h4>
              </div>
            </b-col>
            <b-col md="6">
              <div class="detail-info">
                <span>General Information</span>
                <h4>{{customerInformation.tp_general_information || '-'}}</h4>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <div class="detail-info">
                <span>Source</span>
                <h4>{{ $parent.prospectSrc(customerInformation.tp_source).label || '-'}}</h4>
              </div>
            </b-col>
            <b-col md="3">
              <div class="detail-info">
                <span>Contact Via</span>
                <h4>{{ $parent.contactMedia(customerInformation.tp_contact_via).label || '-'}}</h4>
              </div>
            </b-col>
            <b-col md="3">
              <div class="detail-info">
                <span>Status</span>
                <div>
                  <b-badge :variant="$parent.prospectBadge[customerInformation.tp_status_prospect].color">
                    {{ $parent.prospectBadge[customerInformation.tp_status_prospect].word }}
                  </b-badge>
                </div>
              </div>
            </b-col>
            <b-col md="8" v-if="customerInformation.tp_status_prospect == 'P'">
              <div class="detail-info">
                <span>Pending Reason</span>
                <h4>{{customerInformation.tp_pending_reason || '-'}}</h4>
              </div>
            </b-col>
            <b-col md="8" v-else-if="customerInformation.tp_status_prospect == 'R'">
              <div class="detail-info">
                <span>Reject Reason</span>
                <h4>{{customerInformation.tp_reject_reason || '-'}}</h4>
              </div>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Follow Up History">
          <template v-if="$parent.lengthArr(followUpHistory)">
            <b-row>
              <b-col lg="8" md="10">
                <div class="timeline">
                  <div class="timeline-box" v-for="(value, key) in followUpHistory" :key="key">
                    <div class="timeline-head">
                      <h4 class="timeline__title">{{ value.tfu_date | moment('LL') }}</h4>
                      <b-badge v-if="value.tfu_status_done == 'done'" :variant="$parent.prospectBadge[value.tfu_status].color">
                        {{ $parent.prospectBadge[value.tfu_status].word }}
                      </b-badge>
                      <p v-else>Not Finished yet</p>
                      <span v-if="value.tfu_status_done == 'done'">Followed Up by: {{ value.tfu_person }}</span>
                    </div>
                    <div class="timeline-body">
                      <p>{{ value.tfu_result }}</p>
                      <p v-if="value.tfu_pending_reason">Pending Reason: {{ value.tfu_pending_reason }}</p>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
          <template v-else>
            <h3>Follow Up History is Empty.</h3>
          </template>
        </b-tab>
        <b-tab title="Survey History" active v-if="$parent.lengthArr(surveyHistory)">
          <template v-if="$parent.lengthArr(surveyHistory)">
            <b-row>
              <b-col lg="6" md="8">
                <div class="detail-info">
                  <span>Survey Address</span>
                  <h4>{{ (surveyHistory[0] || {tss_address: ''}).tss_address }}</h4>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8" md="10">
                <div class="timeline">
                  <div class="timeline-box" v-for="(value, index) in surveyHistory" :key="index">
                    <div class="timeline-head">
                      <h4 class="timeline__title d-block">{{ value.tss_date | moment('LLL') }}</h4>                      
                      <template v-for="(v, k) in value.tss_image">
                        <b-button v-if="v" size="sm" variant="outline-info" @click="setLightBox(v)" :key="k" class="mt-2 mb-2" :class="k != 0 ? 'ml-2' : ''">                          
                          <i class="fas fa-image"></i>
                          See Photo
                        </b-button>
                      </template>
                      <p>Surveyor: {{ value.ms_name }}</p>
                    </div>
                    <div class="timeline-body">
                      <p>{{value.tss_result}}</p>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
          <template v-else>
            <h3>Survey History is Empty.</h3>
          </template>
        </b-tab>
        <!-- <b-tab title="Payment History"></b-tab> -->
      </b-tabs>
    </b-card>
    <div class="media-overlay" :class="showSurveyImg ? 'show' : ''" @click.stop="showSurveyImg = false">
      <div class="media-content" @click.stop>
        <div class="media-dialog parent-wrapper">
          <b-img :src="$parent.uploader(detailImg)" />
          <b-button size="sm" @click.stop="showSurveyImg = false" class="close-button btn" variant="danger"><i class="fas fa-times"></i></b-button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  export default{
    props:{
      customerInformation: Object,
      followUpHistory: Array,
      surveyHistory: Array
    },
    data() {
      return {
        showSurveyImg: false,
        detailImg: ''
      }
    },
    methods: {
      setLightBox(src){
        this.detailImg = src
        this.showSurveyImg = true
      }
    },
    mounted(){
      setTimeout(() => {       
        if(!this.customerInformation) this.$parent.$router.push({ name: this.$parent.modulePage })      
      }, 800)
    }
  }
</script>
